<template>
  <div id="top-header">
    <div class="dataweather">
      <div>
        {{ localweather.city }}
        {{ localweather.wea }}
        {{ localweather.tem2 }}℃ - {{ localweather.tem1 }}℃
      </div>
    </div>
    <div class="datatime">
      <span>
        {{ dataTime.dateYear }} {{ dataTime.dateWeek }} {{ dataTime.dateDay }}
      </span>
    </div>
    <dv-decoration-8 class="header-right-decoration" />
    <dv-decoration-5
      class="header-center-decoration"
      style="height: 0.6rem"
      :dur="3"
    />
    <dv-decoration-8 class="header-right-decoration" :reverse="true" />
    <div class="center-title">
      <!-- 巷电 -->
      <img :src="screen_logo" alt="" srcset="" />
      <!-- <p>{{ title }}</p> -->
    </div>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import { weatherOption } from "../config/weatherOption";
import axios from "axios";
import axiosApi from "../utils/request.js";
export default {
  name: "topHeader",
  props: {
    screen_logo: String,
    title: String,
  },
  data() {
    return {
      localweather: [],
      weatherImg: "",
      dataTime: {
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      },
    };
  },
  created() {
    this.getDateTime();
    this.cancelLoading();
    this.requestGetWeather();
  },
  methods: {
    // 获取时间
    getDateTime() {
      setInterval(() => {
        this.dataTime.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dataTime.dateWeek = this.dataTime.weekday[new Date().getDay()];
        this.dataTime.dateDay = formatTime(new Date(), "HH: mm: ss");
      }, 1000);
    },
    // 获取天气
    requestGetWeather() {
      var _this = this;
      axios
        .get(
          "https://www.tianqiapi.com/api?version=v6&appid=59759797&appsecret=Am8OxyE4"
        )
        .then(function (response) {
          _this.localweather = response.data;
          // _this.weatherImg = 'http://tq.daodaoim.com//tianqiapi/skin/pitaya/' + response.data.wea_img +'.png'
        })
        .catch(() => {});
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.center-title {
  display: flex;
  align-items: center;
  margin-top:0.1rem;
  img {
    width: auto;
    height: 40px;
  }
}
#top-header {
  position: relative;
  width: 100%;
  /*height: 100px;*/
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;

  .header-center-decoration {
    width: 40%;
    height: 1rem;
    margin-top: 0.4rem;
  }

  .header-left-decoration,
  .header-right-decoration {
    width: 25%;
    height: 0.8rem;
  }

  .center-title {
    position: absolute;
    font-size: 0.4rem;
    font-weight: bold;
    left: 50%;
    // top: 0.4rem;
    transform: translateX(-50%);
  }
}
.dataweather {
  z-index: 999;
  position: absolute;
  top: 0.1rem;
  left: 1.1rem;
  font-size: 0.22rem;
  color: #fff;

  img {
    width: 0.3rem;
    height: 0.3rem;
    filter: brightness(3);
    vertical-align: bottom;
  }
}

.datatime {
  z-index: 999;
  position: absolute;
  top: 0.1rem;
  right: 1.1rem;
  font-size: 0.22rem;
  color: #fff;
}
</style>
