<template>
  <div id="index">
    <dv-full-screen-container>
      <div class="bg">
        <div v-if="loading" class="bg loading" :style="`height:${innerHeight}px`">
          <dv-decoration-9 class="loading_icon">Loading...</dv-decoration-9>
        </div>
        <div>
          <top-header :screen_logo="details.screen_logo" title="满格了数据大屏" />
          <dv-border-box-1>
            <div class="body-box-index">
              <div class="content-box">
                <!-- 左-->
                <div>
                  <div>
                    <dv-border-box-7
                      :style="'height:' + ceil_height / 3 + 'px'"
                    >
                      <div class="desc_video">
                        <video ref="videoPlay" preload="auto" autoplay muted loop class="video">
                          <source
                            :src="details.screen_video"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </dv-border-box-7>
                  </div>
                  <div style="margin: 10px 0">
                    <dv-border-box-7
                      :style="'height:' + (ceil_height / 3 - 10) + 'px'"
                    >
                      <div class="title">
                        <dv-decoration-3 style="width: 250px; height: 30px" />
                        设备分布
                      </div>
                      <div id="equipment"></div>
                      <!-- <dv-scroll-ranking-board
                      :config="equipmentConfig"
                      class="equipment"
                      style="width: 90%; height: 90%; margin: 0.2rem"
                    />  -->
                    </dv-border-box-7>
                  </div>

                  <dv-border-box-7
                    :style="'height:' + (ceil_height / 3 - 10) + 'px'"
                  >
                    <div class="title">
                      <dv-decoration-3 style="width: 250px; height: 30px" />
                      会员数
                    </div>
                    <div id="membertotal"></div>
                  </dv-border-box-7>
                </div>
                <!-- 中 -->
                <div style="margin: 0 10px">
                  <div class="num_list" :style="`height:${ceil_height / 9}px;`">
                    <dv-border-box-12 style="width: 20%">
                      <div class="PropertyLine">
                        <div class="num1">{{ details.equipmentnum }}</div>
                        <div class="qingkuan">设备数</div>
                      </div>
                    </dv-border-box-12>
                    <dv-border-box-12 style="width: 20%">
                      <div class="PropertyLine">
                        <div class="num1">{{ details.cdordernum }}</div>
                        <div class="qingkuan">订单数</div>
                      </div>
                    </dv-border-box-12>
                    <dv-border-box-12 style="width: 20%">
                      <div class="PropertyLine">
                        <div class="num1">{{ details.cdordermoney }}</div>
                        <div class="qingkuan">订单金额</div>
                      </div>
                    </dv-border-box-12>
                    <dv-border-box-12 style="width: 20%">
                      <div class="PropertyLine">
                        <div class="num1">{{ details.membernum }}</div>
                        <div class="qingkuan">会员数</div>
                      </div>
                    </dv-border-box-12>

                    <dv-border-box-12 style="width: 20%">
                      <div class="PropertyLine">
                        <div class="num1">{{ details.shopnum }}</div>
                        <div class="qingkuan">商户数</div>
                      </div>
                    </dv-border-box-12>
                  </div>
                  <div :style="'height:' + (ceil_height / 9) * 8 + 'px'">
                    <maps ref="Maps" style="height: 100%" />
                  </div>
                </div>
                <!-- 右 -->
                <div>
                  <dv-border-box-7 :style="'height:' + ceil_height / 3 + 'px'">
                    <div class="title">
                      商户分类
                      <dv-decoration-3 style="width: 250px; height: 30px" />
                    </div>
                    <div id="shopzhanbi"></div>
                  </dv-border-box-7>
                  <div style="margin: 10px 0">
                    <dv-border-box-7
                      :style="'height:' + (ceil_height / 3 - 10) + 'px'"
                    >
                      <div class="title">
                        订单数
                        <dv-decoration-3 style="width: 250px; height: 30px" />
                      </div>
                      <div id="orderNum"></div>
                    </dv-border-box-7>
                  </div>

                  <dv-border-box-7
                    :style="'height:' + (ceil_height / 3 - 10) + 'px'"
                  >
                    <div class="title">
                      订单金额
                      <dv-decoration-3 style="width: 250px; height: 30px" />
                    </div>
                    <div id="orderprice"></div>
                  </dv-border-box-7>
                </div>
              </div>
            </div>
          </dv-border-box-1>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import topHeader from "./topHeader";
import axiosApi from "../utils/request.js";
import maps from "./maps";

export default {
  components: {
    topHeader,
    maps,
  },
  data() {
    return {
      loading: true,
      details: {},
      innerHeight: document.documentElement.clientHeight, //屏幕高度
      ceil_height: document.documentElement.clientHeight - 126, //模块盒子高度
      equipmentConfig: { data: [] },
      shop_fenbu_timer: null,
      timer: null,
      selectIndex: 0,
    };
  },
  mounted() {
    this.getData();
    window.addEventListener("resize", function (e) {
      location.reload();
    });
    this.timer = setInterval(() => {
      this.getData();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.shop_fenbu_timer);
  },
  updated() {
    console.log("updated");
  },
  methods: {
    getData() {
      var that = this;
      axiosApi("/api/statistics/index", {}, "GET").then((res) => {
        //index  virtual
        if (res.data.code == 1) {
          that.loading = false;
          that.details = res.data.data;
          res.data.data.shop.forEach((item) => {
            if (!item.name) {
              item.name = "其他";
            }
            item.value = item.total;
          });
          that.shopclass = res.data.data.shop;
          that.init_order(); //订单数
          that.price_line(); //订单金额
          that.membertotal_axis(); //柱状图  会员数
          that.shopzhanbi(); //商户分类占比
          that.equipment(); //设备分布
          that.$refs.Maps.hadleMap(that.details.company); 
          that.$nextTick(() => {that.$refs.videoPlay.load()  }) 
        }
      });
    },
    // 会员数  柱状图
    membertotal_axis() {
      var that = this;
      var data = [];
      for (var key in that.details.membertotal) {
        data.push({ name: key, value: that.details.membertotal[key] });
      }
      var xdata = [];
      var ydata = [];
      data.forEach((item, i) => {
        xdata.push(item.name);
        ydata.push(item.value);
      });
      var orderchartbar = that.$echarts.init(
        document.getElementById("membertotal")
      );
      var option = {
        color: ["#2ea5c3", "#e08f68"],
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          //top:80,
          left: 10,
          top: 20,
          bottom: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            interval: 0,
            rotate: 60, //倾斜角度
            color: "#d5dbff", // 刻度标签文字的颜色
            fontSize: 12, // 文字的字体大小
            // 使用字符串模板，模板变量为刻度默认标签 {value}
            formatter: "{value}",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            lineStyle: {
              color: "#0C1A5B",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#0C1A5B",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              },
              formatter: function (value) {
                return value;
              },
              color: "rgba(255,255,255,0.6)",
            },
          },
        ],
        series: [
          {
            name: "会员数",
            yAxisIndex: 0,
            //barMinHeight:5,
            // barCategoryGap: '30%',
            itemStyle: {
              normal: {
                label: {
                  show: false,
                  position: "top",
                  color: "#fff",
                  fontSize: 12,
                },
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "#A43EE5",
                  },
                  {
                    offset: 1,
                    color: "#CE7C13",
                  },
                ]),
                opacity: 1,
                shadowBlur: 5,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            type: "bar",
            data: ydata,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      orderchartbar.setOption(option);
    },
    // 订单数
    init_order() {
      var that = this;
      var data = [];
      for (var key in that.details.cdordertotal) {
        data.push({ name: key, value: that.details.cdordertotal[key] });
      }
      var xdata = [];
      var ydata = [];
      data.forEach((item, i) => {
        xdata.push(item.name);
        ydata.push(item.value);
      });
      var orderchartLine = that.$echarts.init(
        document.getElementById("orderNum")
      );
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          top: "10%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              interval: 0,
              rotate: 60, //倾斜角度
              show: true,
              textStyle: {
                color: "#c7cbcd",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0a2b52",
                width: 0.5, //这里是为了突出显示加上的
              },
            },
            data: xdata,
          },
        ],
        yAxis: [
          {
            type: "value",
            //  splitNumber : 10,
            // interval: 50, //坐标抽分割间隔
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#0a2b52",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#c7cbcd", //字体颜色
              },
            },
            splitLine: {
              //保留网格线
              show: true,
              lineStyle: {
                //y轴网格线设置
                color: "#0a2b52",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "订单数",
            type: "line",
            //  smooth: true,   //圆滑
            //  symbol: "none", //去掉折线点
            //stack: i, //数据区叠加 问题
            // symbolSize: 5, //折线点的大小
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#63D79A",
                    },
                    {
                      offset: 1,
                      color: "#051f48",
                    },
                  ],
                  false
                ),
              },
            },
            itemStyle: {
              normal: {
                color: "#63D79A",
                borderColor: "#63D79A",
                borderWidth: 5,
              },
            },
            data: ydata,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      orderchartLine.setOption(option);
    },
    // 订单金额
    price_line() {
      var that = this;
      var data = [];
      for (var key in that.details.cdorderamount) {
        data.push({ name: key, value: that.details.cdorderamount[key] });
      }
      var xdata = [];
      var ydata = [];
      data.forEach((item, i) => {
        xdata.push(item.name);
        ydata.push(item.value);
      });
      var orderchartLines = that.$echarts.init(
        document.getElementById("orderprice")
      );
      // 指定图表的配置项和数据
      var option = {
        // backgroundColor: "#010334",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          top: "10%",
          bottom: "2%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLabel: {
              interval: 0,
              rotate: 60, //倾斜角度
              show: true,
              textStyle: {
                color: "#c7cbcd",
              },
            },
            axisLine: {
              lineStyle: {
                color: "#0a2b52",
                width: 0.5, //这里是为了突出显示加上的
              },
            },
            data: xdata,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#0a2b52",
                width: 1, //这里是为了突出显示加上的
              },
            },

            axisLabel: {
              show: true,
              textStyle: {
                color: "#c7cbcd", //字体颜色
              },
            },
            splitLine: {
              //保留网格线
              show: true,
              lineStyle: {
                //y轴网格线设置
                color: "#0a2b52",
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "订单金额",
            type: "line",
            // smooth: true,
            // symbol: "none", //去掉折线点
            // symbolSize: 5, //折线点的大小
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#fff369",
                    },
                    {
                      offset: 1,
                      color: "#0f1e21", // 100% 处的颜色
                    },
                  ],
                  false
                ),
              },
            },
            itemStyle: {
              normal: {
                color: "#fff369",
                borderColor: "#fff369",
                borderWidth: 5,
              },
            },
            data: ydata,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      orderchartLines.setOption(option);
    },
    // 商户分类占比
    shopzhanbi() {
      var that = this;
      var chart2 = that.$echarts.init(document.getElementById("shopzhanbi"));
      var option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 10,
          top: 20,
          bottom: 20,
          data: that.shopclass,
          textStyle: {
            color: "#fff",
          },
          formatter: function (name) {
            return "" + name;
          },
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "70%"],
            center: ["40%", "50%"],
            data: that.shopclass,
            // roseType: "radius",
            label: {
              color: "rgba(255, 255, 255, 1)",
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 100;
            },
          },
        ],
      };
      chart2.setOption(option);
      var dataLen = that.shopclass.length;
      chart2.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: that.selectIndex,
      }); //设置默认选中高亮部分
      clearInterval(this.shop_fenbu_timer);
      this.shop_fenbu_timer = setInterval(function () {
        that.selectIndex++;
        chart2.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: that.selectIndex == 0 ? dataLen - 1 : that.selectIndex - 1,
        });
        chart2.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: that.selectIndex == dataLen ? 0 : that.selectIndex,
        }); //设置默认选中高亮部分
        if (that.selectIndex == dataLen) {
          that.selectIndex = 0;
        }
      }, 3000);
    },
    // 设备分布
    equipment() {
      var that = this;
      var province = [];
      var seriesdata = [];
      that.details.equipment.forEach((item) => {
        item.province = !item.province ? "其他" : item.province;
        province.push(item.province);
        seriesdata.push({
          name: item.province,
          value: item.total,
        });
      });
      var xdata = [];
      var ydata = [];
      seriesdata.forEach((item, i) => {
        if (i % 2 == 0) {
          item.name = "\n" + item.name;
        }
        xdata.push(item.name);
        ydata.push(item.value);
      });

      var chart1 = that.$echarts.init(document.getElementById("equipment"));
      var option = {
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          //top:80,
          left: 10,
          top: 20,
          bottom: 10,
          right: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xdata,
          axisLabel: {
            interval: 0,
            rotate: 0, //倾斜角度
            color: "#d5dbff", // 刻度标签文字的颜色
            fontSize: 12, // 文字的字体大小
            // 使用字符串模板，模板变量为刻度默认标签 {value}
            formatter: "{value}",
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            lineStyle: {
              color: "#0C1A5B",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "#0C1A5B",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 12,
              },
              formatter: function (value) {
                return value;
              },
              color: "rgba(255,255,255,0.6)",
            },
          },
        ],
        series: [
          {
            yAxisIndex: 0,
            barWidth: 10,
            itemStyle: {
              normal: {
                color: function (params) {
                  // build a color map as your need.
                  var colorList = [
                    "#C1232B",
                    "#B5C334",
                    "#FCCE10",
                    "#E87C25",
                    "#27727B",
                    "#FE8463",
                    "#9BCA63",
                    "#FAD860",
                    "#F3A43B",
                    "#60C0DD",
                    "#D7504B",
                    "#C6E579",
                    "#F4E001",
                    "#F0805A",
                    "#26C0C0",
                  ];
                  return colorList[params.dataIndex];
                },
                label: {
                  show: true,
                  position: "top",
                  fontSize: 12,
                },
                shadowBlur: 5,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            type: "bar",
            data: ydata,
          },
        ],
      };
      chart1.setOption(option);
    },
    // 设备分布
    // equipment() {
    //   var that = this;
    //   var province = [];
    //   var seriesdata = [];
    //   that.details.equipment.forEach((item) => {
    //     item.province = !item.province ? "其他" : item.province;
    //     province.push(item.province);
    //     seriesdata.push({
    //       name: item.province,
    //       value: item.total - 0,
    //     });
    //   });
    //   that.equipmentConfig = {
    //     data: seriesdata,
    //     rowNum:seriesdata.length,
    //   };
    // },
  },
};
</script>


<style lang="scss" scoped>
// @import "../assets/scss/index.scss";
#index {
  color: #d3d6dd;
  background-color: #000000;
  width: 100%;
  height: 100%;
  .bg {
    padding: 0;
    background-image: url("../assets/images/big_bg.png");
    background-size: cover;
    background-position: center center;
  }
  .loading {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading_icon {
    width: 4rem;
    height: 4rem;
    font-size: 0.3rem;
  }
  .body-box-index {
    padding: 0.3rem 0.2rem;
    //margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    //下方区域的布局
    .content-box {
      display: grid;
      grid-template-columns: 2fr 4fr 2fr;
    }
    .content-box2 {
      // display: grid;
      // grid-template-columns: 3fr 2fr 3fr;
      display: flex;
      justify-content: space-between;
    }
    //下方区域的布局
    .content-five {
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;
    }
    // 底部数据
    .bototm-box {
      margin-top: 0.125rem;
      display: grid;
      grid-template-columns: repeat(2, 50%);
    }
  }

  .num_list {
    display: flex;
    flex-wrap: wrap;
  }
  .PropertyLine {
    height: 100%;
    line-height: 1.5;
    font-size: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .num1 {
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 0.3rem;
  }
}
#orderNum,
#orderprice,
#membertotal,
#equipment,
#shopzhanbi {
  width: 100%;
  height: calc(100% - 0.6rem);
  //   overflow-y: scroll;
}
.title {
  font-size: 0.25rem;
  display: flex;
  align-items: center;
  margin: 0.1rem;
  font-weight: bold;
  justify-content: space-between;
}
.desc_video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.desc_video .video {
  width: 95%;
  height: 95%;
  display: block; 
}
</style>