<template>
  <div class="echarts">
    <div
      id="map"
      :style="{ height: '100%', width: '100%' }"
      ref="myEchart"
    ></div>
  </div>
</template>
<script>
import axios from "axios";
import echarts from "echarts";
import "../../node_modules/echarts/map/js/world.js";
import "../../node_modules/echarts/map/js/china.js"; // 引入中国地图数据
import mapJson from "../config/china.json";
import axiosApi from "../utils/request.js";
export default {
  name: "echarts",
  data() {
    return {
      timer: null,
      shopList: [],
    };
  },
  methods: {
    hadleMap(company) {
      var map = new BMap.Map("container", {
        enableMapClick: true,
      });
      this.shopList = company;
      this.shopList.forEach((item) => {
        var localSearch = new BMap.LocalSearch(map);
        var keyword = item.cityname;
        localSearch.setSearchCompleteCallback((searchResult) => {
          var poi = searchResult.getPoi(0);
          this.shopList.push({
            streetName: item.nickname,
            latitude: Number(poi.point.lat),
            longitude: Number(poi.point.lng),
          });
          this.init();
        });
        localSearch.search(keyword);
      });
    },
    init() {
      var that = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("map"));
      // 地图数据
      echarts.registerMap(name, mapJson);
      myChart.setOption({
        geo: {
          type: "map",
          map: "china",
          roam: false,
          zoom: 1.2, 
          label: {
            normal: {
              show: true,
              textStyle: {
                color: "rgba(255,255,255,0.5)",
                fontSize:12
              },
            },
            emphasis: {
              // 对应的鼠标悬浮效果
              show: false,
              textStyle: {
                color: "rgba(255,255,255,1)",
                fontSize:18 
              },
            },
          },
          itemStyle: {
            normal: {
              areaColor: "rgba(255,255,255,0.1)",
              borderColor: "#f1f1f1",
            },
            emphasis: {
              borderWidth: 0,
              borderColor: "#f1f1f1", 
              areaColor: "rgba(255,255,255,0.3)", 
            },
          },
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            lineStyle: {
              color: "#57617B", //显示竖线颜色
            },
          },
          backgroundColor: "rgba(15,51,92,0.7)", //提示框背景色
          borderColor: "#2BEDF6", //tooltip边框颜色
          borderWidth: 2,
          formatter: function (param) {
            //自定义tooltip内容
            var text = "";
            text +=
              '<div style="display:flex;flex-direction:row;">' +
              '<div style="background-color:#03D16D;height:auto;width:5px;margin-right:5px;"></div>' +
              '<div style="display:flex;flex-direction:column;">' +
              "<span>" +
              param.name +
              "</span>";
            ("</div>");
            ("</div>");
            return text;
          },
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            animation: true,
            rippleEffect: {
              brushType: "stroke",
            },
            symbolSize: function (val, params) {
              return 8;
            },
            data: this.shopList.map(function (itemOpt) {
              return {
                name: itemOpt.streetName,
                value: [itemOpt.longitude, itemOpt.latitude], 
                itemStyle: {
                  normal: {
                    color: {
                      type: "radial", 
                      colorStops: [
                        {
                          offset: 0,
                          color: "rgba(255,255,0,1)", // 0% 处的颜色
                        },
                        {
                          offset: 1,
                          color: "rgba(255,255,0,0.1)", // 100% 处的颜色
                        },
                      ],
                      global: false, // 缺省为 false
                    },
                  },
                },
              };
            }),
          },
        ],
      });
      // 地图点击
      myChart.on("click", function (e) {
        if (e.value) {
          that.$emit("map-change", e.value);
        }
      });
    },
  },
};
</script>